
h1, h2, h3, h4, p {
    color: $white;
    font-weight: $default-font-weight;
}

.page {
    font-family: $default-font;
    background-color: $dark-gray;
    display: grid;
    grid-template-areas: 
        "logo"
        "title"
        "nav"
        "content"
        "footer";

    grid-template-rows: auto auto auto 3fr auto;
    grid-template-columns: 1fr;
    min-height: calc(100vh);

    &__header__logo {
        grid-area: logo;
        text-align: center;
    }

    &__header__content {
        grid-area: title;
        text-align: center;
        &__name {
            font-size: 50px;
            margin-bottom: 0px;
        }
        &__title {
            margin-top: 5px;
            font-size: 30px;
        }
        &__location {
            font-size: 25px;
        }
    }

    &__header__nav {
        grid-area: nav;
    }

    &__main {
        grid-area: content;

        &__header {
            font-size: 40px;
            font-weight: 400;
            margin-left: 2.5vw;
            margin-top: 5vh;
            margin-bottom: 2vh;

            @media (min-width: $screen-sm) {
                margin-top: 10vh;
            }
        }
    }

    &__footer {
        grid-area: footer;
    }

    @media (min-width: $screen-sm) {
        grid-template-areas: 
        ". content"
        "logo content"
        "title content"
        "nav content"
        ". content"
        ". footer";

        max-height: calc(100vh);

        grid-template-rows: 1fr auto auto auto 1fr auto;
        grid-template-columns: 25% auto;

        &__main {
            grid-area: content;
            overflow-y: scroll;
        }
    }
}

.social-media__container {
    font-size: 30px;

    a {
        color: $white;
        padding: 0 1vw 0 1vw;
    }

    &__text {
        line-height: 0; 
        font-size: 0;
        color: transparent; 
    }
}
