//Screen sizes - stolen from Bootstrap
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

// Colours
$black: #000;
$white: #fff;
$gray: #141414;
$light-gray: #2b2b2b;
$dark-gray: #1a1a1a;
$blue: #004674;

//Fonts
$default-font: raleway, Roboto, sans-serif;

//Font Weight
$default-font-weight: normal;