.nav {
    list-style: none;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    margin-top: 3vh;

    &__item {
        a {
            font-size: 18px;
            display: block;
            padding: 10px 0px;
            text-decoration: underline;
            color: $white;
        }
    }
}