.project-card {
    display: block;
    width: 94%;
    margin: 1vh 2.5vw 1vh 2.5vw;
    text-decoration: none;
    padding: 0 1vw 2vh 1vw;
    background-color: $light-gray;
    border-radius: 5px;
    transition: all 0.4s ease-in-out 0s;
    text-align: justify;

    @media (min-width: $screen-sm) {
        padding-right: 1vw;
        width: 90%;

        &:hover {
            transform: scale(1.07);
            box-shadow: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__name {
        font-size: 24px;
        margin-bottom: -2vh;
        text-align: center;
    }

    &__description {
        font-size: 18px;
        padding: 0 2vw 0 2vw;
    }

    &__icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
    }
}